/* styles.css veya benzeri bir stil dosyasına ekleyin */
.cart-badge {
    position: absolute;
    top: -10px;
    left: -4px;
    width: 20px;
    background-color: red;
    color: white;
    border-radius: 50%;
    /* padding: 1px 4px; */
    font-size: 0.75rem;
    text-align: center;
}
